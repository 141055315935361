import Vue from "vue";
import CollapsePanel from "@/components/Content/Collapse/Panel.vue";
import SelectedAndFilters from "@/views/Admin/Persons/V10/Dependencies/Select/Commons/ChipFilter/index.vue";
import PaginatedChips from "@/views/Admin/Persons/V10/Dependencies/Common/Chips/Paginated/index.vue";
import PaginationFilter from "@/components/Content/Pagination/Filter/index.vue";
import { mapGetters } from "vuex";
import {
	PersonKeyof,
	TypeFilterKey,
} from "@/interfaces/persons/v10/types";
import { ElementData } from "@/interfaces/persons/v10/person";
import FilterChips from "@/views/Admin/Persons/V10/Dependencies/Dialogs/FilterChips/index.vue";
import {
	FILTER_SECTIONS,
	PaginationEntity,
} from "@/models/persons/v10/Implements";

export default Vue.extend({
	name: "CardCollapsePanel",
	props: {
		item: {
			type: Object,
			default: function () {
				return {};
			},
		},
		expanded: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		CollapsePanel,
		SelectedAndFilters,
		PaginatedChips,
		PaginationFilter,
		FilterChips,
	},
	data: () => ({
		expand_filters: false,
		pagination: new PaginationEntity(),
	}),
	created() {},
	mounted() {
		this.$nextTick(async () => {});
	},
	computed: {
		...mapGetters("person", ["getPerson"]),
		getSelectedItem(): ElementData[] {
			return this.getPerson.getSelectedFilters(this.item.type);
		},

		getTooltipText() {
			let message = `persons.v10.panelsPois.subPanel.${this.item.type}.clear-filters`;
			return this.$t(message);
		},

		hasSelectedItem() {
			return this.getSelectedItem.length;
		},

		showConditional() {
			return this.getPerson.showConditional(this.item.type);
		},

		enableClearFilters() {
			return FILTER_SECTIONS.includes(this.item.type);
		},

		canClose() {
			return FILTER_SECTIONS.includes(this.item.type);
		},
		getExpandFilterText() {
			const type: string = this.expand_filters ? "close" : "show";
			const title = `modal.filters.${this.item.type}.${type}`;
			return this.$t(title);
		},
		getExpandFilterIcon() {
			return this.expand_filters ? "mdi-close" : "mdi-arrow-expand";
		},
	},
	methods: {
		toggleExpand() {
			this.expand_filters = !this.expand_filters;
		},
		isTemplate(template: PersonKeyof, type: PersonKeyof) {
			return template === type;
		},
		async handleClearFilters() {
			this.getPerson.clearAllFilterByType(this.item.type);
			await this.pagination.setTotalPages(this.getSelectedItem.length);
		},
		async handleRemove(event: { item: ElementData }) {
			let { item } = event;
			this.$emit("remove", { type: this.item.type, item });
			await this.getPerson.removeFromSelected(
				item,
				this.item.type,
				TypeFilterKey.SELECTED
			);
			await this.pagination.setTotalPages(this.getSelectedItem.length);
			this.pagination.verifyPage();
		},
		handleClose() {
			this.expand_filters = false;
		},
	},
	watch: {
		getSelectedItem(val) {
			this.pagination.setTotalPages(val.length);
		},
		expanded() {
			this.expand_filters = false;
		},
	},
});
