import Vue from "vue";
import ChipFilterGroup from "@/views/Admin/Persons/V10/Dependencies/Common/Chips/Filters/index.vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import { ElementData } from "@/interfaces/persons/v10/person";
import { debounce } from "lodash";

export default Vue.extend({
	name: "CardChip",

	props: {
		title: {
			type: String,
			default: "",
		},
		term: {
			type: String,
			default: "",
		},
		type: {
			type: String,
			default: "",
		},
		filterName: {
			type: String,
			default: "",
		},
		filters: {
			type: Array,
			default: function () {
				return [];
			},
		},
	},

	components: {
		ChipFilterGroup,
		CardTextField,
	},

	data: () => ({
		syncTerm: null,
	}),

	created() {},

	async mounted() {
		this.$nextTick(async () => {});
	},

	computed: {
		modelTerm: {
			get() {
				return this.term;
			},
			set(term) {
				this.modelDebounceTerm(term);
			},
		},
		getFiltered() {
			let filtered = (this.filters || []) as ElementData[];

			const term: string = this.modelTerm;

			if (!term) return filtered;

			return filtered.filter((f) =>
				f.value.toLowerCase().includes(term.toLowerCase())
			);
		},
		getCountFiltered() {
			if (!this.filters.length) return "0";
			if (!this.modelTerm) return this.filters.length;
			return `${this.getFiltered.length || "0"} / ${this.filters.length}`;
		},
		getNotFountText() {
			let message = `persons.v10.panelsPois.subPanel.${this.type}.not-found.${this.filterName}`;
			return this.$t(message);
		},
	},

	methods: {
		handleRemove(event: { item: ElementData }) {
			this.$emit("remove", event);
		},
		updateTerm(term: string) {
			this.$emit("term", {
				type: this.type,
				key: this.filterName,
				value: term,
			});
		},
		modelDebounceTerm: debounce(async function (val: string | null) {
			await this.updateTerm(val);
		}, 750),
	},

	watch: {},
});
