import Vue from "vue";

export default Vue.extend({
	name: "PaginationFilter",
	model: {
		prop: "model_data",
		event: "input",
	},
	props: {
		model_data: {
			type: Object,
			default: function () {
				return {};
			},
		},
		items: {
			type: Array,
			default: function () {
				return [];
			},
		},
	},
	components: {},
	data: () => ({}),
	created() {},
	mounted() {
		this.$nextTick(async () => {});
	},
	computed: {
		modelData: {
			set(val: any) {
				this.$emit("input", val);
			},
			get() {
				return this.model_data;
			},
		},
		displayedItems() {
			return this.modelData.getPaginatedItems(this.items);
		},
	},
	methods: {
		previousPage() {},
		nextPage() {},
	},
	watch: {},
});
