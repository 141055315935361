import { SnackNotification, SnactType } from "@/interfaces/snack";

export class SnackNotificationEntity implements SnackNotification {
	type: SnactType = SnactType.INFO;
	message: string = "";

	constructor() {}

	setSnackData(properties?: SnackNotification) {
		this.type = properties?.type || SnactType.INFO;
		this.message = properties?.message || "";
	}
}
